import * as React from "react"
import { Link, graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import styled from "styled-components"

const PreviousNextList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    margin: 0;
`

const BlogPost = ({ data, pageContext }) => {
    const { mdx } = data
    const { body, frontmatter } = mdx
    const { previous, next } = pageContext

    return (
        <Layout pageTitle={frontmatter.title}>
            <div className="px-5">
                <h2>{ frontmatter.title }</h2>
                <p>{ frontmatter.date }</p>
                <MDXProvider>
                    <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
            </div>
            <nav className="px-5">
                <PreviousNextList>
                    <li>
                        {previous && (
                        <Link to={`/blog/${previous.slug}`} rel="prev">
                            ← {previous.frontmatter.title}
                        </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                        <Link to={`/blog/${next.slug}`} rel="next">
                            {next.frontmatter.title} →
                        </Link>
                        )}
                    </li>
                </PreviousNextList>
            </nav>
        </Layout>
    )
}

export const query = graphql`
    query BlogQuery($slug: String) {
        site {
            siteMetadata {
                title
            }
        }
        mdx(slug: { eq: $slug }) {
            id
            body
            slug
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
            }
        }
    }
`

export default BlogPost
